<template>
  <TagbyPrimaryModal
    :visible="visibleModal"
    title="스토어 정산"
    content="선택하신 스토어의 정산을 진행하시겠습니까"
    @ok="adjust"
    @hidden="turnOffModal"
  />
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyPrimaryModal from '@/components/TagbyPrimaryModal.vue'
import useAdjustment from '../../useAdjustment'

export default defineComponent({
  components: {
    TagbyPrimaryModal,
  },
  setup() {
    return {
      ...useAdjustment(),
    }
  },
})
</script>
