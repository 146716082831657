<template>
  <div>
    <!-- <TagbyFilterV2
      :initialParams="filterParams"
      :categoryOptions="categoryOptions"
      @search="search"
    /> -->
    <TagbyList
      v-model="currentPage"
      :items="mappedItems"
      :fields="tableColumns"
      :busy="isBusy"
      :current-page="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      hover
      @page-change="changePage"
    >

      <template #cell(title)="data">
        <BLink
          href="#"
          @click="turnOnSidebar(data.item)"
        >
          {{ data.value }}
        </BLink>
      </template>

      <template #cell(sale_myshop_count)="data">
        {{ resolveMoney(data.value) }}
      </template>

      <template #cell(sale_myshop_amount)="data">
        {{ resolveMoney(data.value) }}
      </template>

      <template #cell(sale_sample_amount)="data">
        {{ resolveMoney(data.value) }}
      </template>

      <template #cell(actions)="data">
        <BButton
          size="sm"
          variant="outline-primary"
          @click="fetchSummary(data.item)"
        >
          <BSpinner
            v-if="data.item.isLoading"
            small
          />
          요약 데이터 가져오기
        </BButton>
      </template>
    </TagbyList>
    <AdjustmentSidebar />
    <AdjustmentModal />
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BLink,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import useInitialize from './useInitialize'
import useItems from './useItems'
import useSummary from './useSummary'
import useAdjusment from './useAdjustment'
import TagbyFilterV2 from '@/components/TagbyFilterV2.vue'
import TagbyList from '@/components/TagbyList.vue'
import AdjustmentSidebar from './blocks/AdjustmentSidebar'
import AdjustmentModal from './blocks/AdjustmentModal'
import useResolveValue from '@/utils/resolveValue'

export default defineComponent({
  components: {
    TagbyFilterV2,
    BLink,
    BButton,
    BSpinner,
    TagbyList,
    AdjustmentSidebar,
    AdjustmentModal,
  },
  setup() {
    const {
      initialize,
    } = useInitialize()
    initialize()

    const {
      items,
      fetchItems,
      categoryOptions,
      filterParams,
      search,

      tableColumns,
      currentPage,
      perPage,
      totalRows,
      isBusy,
      changePage,
      mappedItems,
    } = useItems()
    fetchItems()

    const {
      fetchSummary,
    } = useSummary()

    const {
      turnOnSidebar,
    } = useAdjusment()

    const {
      resolveMoney,
    } = useResolveValue()

    return {
      items,
      fetchItems,
      categoryOptions,
      filterParams,
      search,

      tableColumns,
      currentPage,
      perPage,
      totalRows,
      isBusy,
      changePage,
      mappedItems,

      fetchSummary,
      turnOnSidebar,

      resolveMoney,
    }
  },
})
</script>
