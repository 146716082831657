import {
  computed,
} from '@vue/composition-api'
import data from './data'
import actions from './actions'
import useToast from '@/utils/toast'
import {
  syncronizeParamsWithUrl,
  replaceRouterQuery,
} from '@/utils/syncUrl'

export default () => {
  const { makeToast } = useToast()
  const categoryOptions = [
    // {
    //   label: '정산기간',
    //   paramName: 'settlement_period_list',
    //   format: 'date',
    // },
    // {
    //   label: '스토어명',
    //   paramName: 'names',
    // },
  ]

  const fetchItems = () => {
    data.isBusy.value = true
    actions.fetchItems({
      ...data.searchParams.value,
    }).then(response => {
      const resData = response.data
      data.items.value = resData.data.map(e => ({ ...e, isLoading: false }))
      data.totalRows.value = resData.page_info.total_count
      data.filterParams.value.total = resData.page_info.total_count
      replaceRouterQuery(data.searchParams.value)
    }).catch(() => {
      makeToast('danger', '목록을 쿼리하는데 실패 했습니다')
      data.items.value = []
      data.totalRows.value = 0
    }).finally(() => {
      data.isBusy.value = false
    })
  }

  const search = params => {
    data.currentPage.value = 1
    data.filterParams.value = {
      ...params,
    }
    syncronizeParamsWithUrl(data.searchParams.value)
    fetchItems()
  }

  const tableColumns = [
    // { key: 'idx', label: 'idx' },
    // { key: 'url_path', label: '스토어 명' },
    // { key: 'sale_amount', label: '총 판매금액' },
    // { key: 'settlement_amount', label: '총 정산금액' },
    // { key: 'remain_settlement_amount', label: '남은 정산금액' },
    { key: 'title', label: 'title' },
    { key: 'actions', label: 'actions' },
    { key: 'sale_myshop_count', label: '매출 발생 스토어 수' },
    { key: 'sale_myshop_amount', label: '스토어 매출 (배송비 제외)' },
    { key: 'sale_sample_amount', label: '샘플 매출 (배송비 제외)' },
  ]

  const changePage = params => {
    data.currentPage.value = params.page
    fetchItems()
  }

  const mappedItems = computed(() => data.items.value.map(item => {
    const summary = data.summary.value[item.title] ?? {}
    return {
      ...item,
      ...summary,
    }
  }))

  return {
    categoryOptions,
    ...data,
    fetchItems,
    tableColumns,
    search,
    changePage,
    mappedItems,
  }
}
