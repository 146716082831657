import moment from "moment"
import data from "./data"
import actions from "./actions"
import useResolveValue from "@/utils/resolveValue"
import useToast from "@/utils/toast"

export default () => {
  const {
    resolveUtcStartDateTime,
    resolveUtcEndDateTime,
  } = useResolveValue()

  const { makeToast } = useToast()

  const fetchMyshops = () => {
    data.adjustment.isBusy.value = true
    const startDate = moment(data.adjustment.selectedTitle.value).format("YYYY-MM-DD HH:mm:ss")
    const endDate = moment(startDate).endOf("month").format("YYYY-MM-DD HH:mm:ss")

    const startAt = resolveUtcStartDateTime(startDate)
    const endAt = resolveUtcEndDateTime(endDate)

    actions.fetchMyshops({
      settlement_period: [startAt, endAt],
    }).then(response => {
      data.adjustment.myshops.value = response.data.data
    }).catch(() => {
      makeToast("danger", "정산 정보를 가져오는데 실패했습니다")
    }).finally(() => {
      data.adjustment.isBusy.value = false
    })
  }
  const turnOnSidebar = item => {
    data.adjustment.selectedTitle.value = item.title
    fetchMyshops()
    data.adjustment.visibleSidebar.value = true
  }
  const turnOffSidebar = () => {
    data.adjustment.visibleSidebar.value = false
  }
  const tableColumns = [
    { key: "idx", label: "idx" },
    { key: "url_path", label: "스토어 명" },
    { key: "sale_amount", label: "총 판매금액" },
    { key: "settlement_amount", label: "총 정산금액" },
    { key: "remain_settlement_amount", label: "남은 정산금액" },
  ]

  const selectAll = () => {
    const tableRef = data.adjustment.myshopTableRef.value
    if (tableRef.selectedRows.filter(isSelected => isSelected).length === tableRef.items.length) {
      tableRef.clearSelected()
    } else {
      tableRef.selectAllRows()
    }
  }

  const turnOnModal = () => {
    data.adjustment.visibleModal.value = true
  }
  const turnOffModal = () => {
    data.adjustment.visibleModal.value = false
  }

  const adjust = () => {
    const tableRef = data.adjustment.myshopTableRef.value
    const selectedItems = tableRef.items.filter((item, i) => tableRef.selectedRows[i])
    const myshopIdxList = selectedItems.map(item => item.idx)

    const startDate = moment(data.adjustment.selectedTitle.value).format("YYYY-MM-DD HH:mm:ss")
    const endDate = moment(startDate).endOf("month").format("YYYY-MM-DD HH:mm:ss")

    const startAt = resolveUtcStartDateTime(startDate)
    const endAt = resolveUtcEndDateTime(endDate)

    actions.settle({
      myshop_idx_list: myshopIdxList,
      settlement_period: [startAt, endAt],
    }).then(() => {
      makeToast("primary", "정산에 성공했습니다")
      fetchMyshops()
    }).catch(() => {
      makeToast("danger", "정산에 실패했습니다")
    })
  }

  return {
    visibleSidebar: data.adjustment.visibleSidebar,
    turnOnSidebar,
    turnOffSidebar,
    selectedTitle: data.adjustment.selectedTitle,
    tableColumns,
    myshops: data.adjustment.myshops,
    myshopTableRef: data.adjustment.myshopTableRef,
    isBusy: data.adjustment.isBusy,
    selectAll,
    adjust,
    visibleModal: data.adjustment.visibleModal,
    turnOnModal,
    turnOffModal,
    isVendorSalesView: data.isVendorSalesView,
  }
}
