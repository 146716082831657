import moment from 'moment'
import data from './data'
import actions from './actions'
import useResolveValue from '@/utils/resolveValue'
import useToast from '@/utils/toast'

export default () => {
  const getChangedItems = (item, isLoading) => data.items.value.map(el => {
    if (item.title === el.title) {
      return {
        ...el,
        isLoading,
      }
    }
    return el
  })

  const { makeToast } = useToast()

  const {
    resolveUtcStartDateTime,
    resolveUtcEndDateTime,
  } = useResolveValue()

  const fetchSummary = item => {
    data.items.value = getChangedItems(item, true)
    const startDate = moment(item.title).format('YYYY-MM-DD HH:mm:ss')
    const endDate = moment(startDate).endOf('month').format('YYYY-MM-DD HH:mm:ss')

    const startAt = resolveUtcStartDateTime(startDate)
    const endAt = resolveUtcEndDateTime(endDate)

    actions.fetchSummary({
      settlement_period: [startAt, endAt],
    }).then(response => {
      data.summary.value = {
        ...data.summary.value,
        [item.title]: response.data.data,
      }
    }).catch(() => {
      makeToast('danger', '요약 정보를 가져오는데 실패했습니다')
    }).finally(() => {
      data.items.value = getChangedItems(item, false)
    })
  }
  return {
    fetchSummary,
  }
}
