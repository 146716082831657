<template>
  <TagbySidebar
    :title="selectedTitle"
    :visible="visibleSidebar"
    width="40%"
    @hidden="turnOffSidebar"
  >
    <template #content>
      <BRow class="mb-1">
        <BCol class="d-flex">
          <BButton
            variant="outline-secondary"
            class="mr-1"
            @click="selectAll"
          >
            전체 선택
          </BButton>

          <BButton
            variant="outline-primary"
            @click="turnOnModal"
          >
            선택 정산
          </BButton>
          <BFormCheckbox
            v-model="isVendorSalesView"
            class="ml-1"
            switch
          >
            벤더별조회
          </BFormCheckbox>
        </BCol>
      </BRow>
      <BRow>
        <BCol>
          <BTable
            ref="myshopTableRef"
            :items="myshops"
            :fields="tableColumns"
            show-empty
            empty-text="No matching records found"
            small
            responsive
            :busy="isBusy"
            :selectable="!isVendorSalesView"
          >
            <template #cell(idx)="data">
              <div v-if="!isVendorSalesView">
                {{ data.value }}
              </div>
              <div
                v-else
                class="text-primary"
                style="cursor: pointer"
                @click="data.toggleDetails"
              >
                {{ data.value }}
              </div>
            </template>
            <template #cell(sale_amount)="data">
              {{ resolveMoney(data.value) }}
            </template>

            <template #cell(settlement_amount)="data">
              {{ resolveMoney(data.value) }}
            </template>

            <template #cell(remain_settlement_amount)="data">
              {{ resolveMoney(data.value) }}
            </template>

            <template #row-details="data">
              <BTable
                :fields="vendorSalesFields"
                :items="data.item.vendor_sales"
                small
                responsive
                show-empty
              >
                <template #cell(sales_amount)="vendorData">
                  {{ resolveMoney(vendorData.value) }}
                </template>
              </BTable>
            </template>
          </BTable>
        </BCol>
      </BRow>
    </template>
  </TagbySidebar>
</template>

<script>
import { defineComponent } from "@vue/composition-api"
import {
  BTable,
  BRow,
  BCol,
  BButton,
  BFormCheckbox,
} from "bootstrap-vue"
import TagbySidebar from "@/components/TagbySidebar.vue"
import useAdjustment from "../../useAdjustment"
import useResolveValue from "@/utils/resolveValue"

export default defineComponent({
  components: {
    BTable,
    BRow,
    BCol,
    BButton,
    TagbySidebar,
    BFormCheckbox,
  },
  setup() {
    const {
      resolveMoney,
    } = useResolveValue()

    const vendorSalesFields = [
      { key: "idx", label: "idx" },
      { key: "name", label: "이름" },
      { key: "company_name", label: "회사명" },
      { key: "sales_amount", label: "매출" },
    ]

    return {
      ...useAdjustment(),
      resolveMoney,
      vendorSalesFields,
    }
  },
})
</script>
