import {
  ref,
  computed,
} from "@vue/composition-api"

const currentPage = ref()
const perPage = ref()
const totalRows = ref()

const items = ref([])
const isBusy = ref(false)

const filterParams = ref({})
const searchParams = computed(() => ({
  page: currentPage.value,
  size: perPage.value,
  total: totalRows.value,
  ...filterParams.value,
}))

export default {
  currentPage,
  perPage,
  totalRows,
  items,
  isBusy,
  filterParams,
  searchParams,
  isDownloading: ref(false),
  summary: ref({}),
  adjustment: {
    selectedTitle: ref(),
    myshops: ref([]),
    myshopTableRef: ref(),
    visibleSidebar: ref(false),
    isBusy: ref(false),
    visibleModal: ref(false),
  },
  isVendorSalesView: ref(false),
}
